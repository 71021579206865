@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

body {
	background-image: linear-gradient(
		to right,
		#000000,
		#050505,
		#0a090a,
		#0e0d0e,
		#111112,
		#151516,
		#191919,
		#1c1c1d,
		#212223,
		#272829,
		#2c2e2e,
		#323434
	);

	font-family: 'Source Sans Pro', sans-serif;
}

html,
body {
	scroll-behavior: smooth;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
}

/** Particle background */
/** ------------------------------------------------- */
#particles-js {
	/* stay in a constant spot on the page, regardless of scroll */
	position: fixed;
	/* fill the entire page */
	width: 100%;
	height: 100%;
	/* remove the space along the edges */
	margin: 0;
	padding: 0;
	/* position it to start at the top of the page */
	top: 0;
	/* put it behind other content */
	z-index: -1;
}
/** ------------------------------------------------- */
