$percentage: 3%;
$caret-colour: rgba(
	$color: #666666,
	$alpha: 0.9,
);

.subHeading {
	overflow: hidden;
	white-space: nowrap;
	margin: 0 auto;
	letter-spacing: 0.15em;
	max-width: fit-content;
}

.Typewriter__cursor{
	visibility: hidden !important;
}

.Typewriter__cursor::after{
	border-right: 0.05em solid !important;
	content: "";
	visibility: visible;
	margin-left: -0.3em;
}


@keyframes typing {
	0%,
	10%,
	100% {
		width: 0%;
	}

	50%,
	90% {
		width: 100%;
	}

	//* caret animation
	@for $i from 0 to 100 {
		#{$i * $percentage} {
			@if $i % 2 == 0 {
				border-color: transparent;
			} @else {
				border-color: $caret-colour;
			}
		}
	}
}